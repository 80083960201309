<template>
  <v-menu offset-y :close-on-content-click="false">
    <template #activator="{ props }">
      <v-btn
        icon
        class="mx-2"
        :class="{ 'mr-7': notReadNotificationCount > 99, 'ml-1': notReadNotificationCount > 99 }"
        v-bind="props"
      >
        <v-badge v-if="notReadNotificationCount > 0" :content="notReadNotificationCount" color="primary" max="99">
          <v-icon :icon="notificationIcon" />
        </v-badge>

        <v-icon v-else :icon="notificationIcon" />
      </v-btn>
    </template>

    <v-card>
      <v-list max-width="520px" :lines="false">
        <v-list-item :title="t('general.notifications.title')" density="compact" class="pr-1">
          <v-switch
            v-model="onlyNotRead"
            density="compact"
            :label="t('general.notifications.onlyUnread')"
            class="ml-2 mr-4"
          />

          <template #append>
            <v-btn
              v-if="notReadNotificationCount"
              :title="t('general.notifications.btnReadAll')"
              :icon="mdiNotificationClearAll"
              @click="readAll"
            />
          </template>
        </v-list-item>

        <v-divider />

        <v-infinite-scroll
          v-if="hasNotifications"
          :items="notifications"
          :empty-text="t('general.notifications.noMoreNotifications')"
          @load="loadNotifications"
        >
          <template v-for="notification in notifications" :key="notification.id">
            <v-list-item :title="notification.title" density="compact" class="pr-1">
              <template #subtitle>
                <div>{{ notification.body }}</div>
                <div class="fs-xxs">{{ $filters.shortdaymonthhour(notification.date) }}</div>
              </template>

              <template #append>
                <v-btn
                  v-if="notification.read"
                  :title="t('general.notifications.btnUnreadTitle')"
                  class="ml-2"
                  :icon="mdiEmail"
                  @click="unread(notification)"
                />

                <v-btn
                  v-else
                  :title="t('general.notifications.btnReadTitle')"
                  class="ml-2"
                  :icon="mdiEmailOpen"
                  @click="read(notification)"
                />
              </template>
            </v-list-item>

            <v-divider />
          </template>
        </v-infinite-scroll>

        <v-card-text v-else class="text-center">
          <p>{{ t('general.notifications.noNotifications') }}</p>
        </v-card-text>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
import type { INotification } from '@/interfaces/notification'
import { mdiBellRing, mdiBellSleep, mdiEmail, mdiEmailOpen, mdiNotificationClearAll } from '@mdi/js'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import api from '~api'

const { t } = useI18n({ useScope: 'global' })

const notifications = ref<Array<INotification>>([])
const notReadNotificationCount = ref<number>(0)
const onlyNotRead = ref<boolean>(true)
const itemsPerLoad = ref<number>(30)
const loadCount = ref<number>(1)

const refreshIntervalId = ref<NodeJS.Timeout | undefined>(undefined)

onMounted(() => {
  try {
    getNotifications()

    refreshIntervalId.value = setInterval(() => {
      loadCount.value = 1
      getNotifications()
    }, 240000)
  } catch {
    //
  }
})

onUnmounted(() => {
  clearInterval(refreshIntervalId.value)
})

const hasNotifications = computed(() => !!notifications.value && !!notifications.value.length)
const notificationIcon = computed(() => (notReadNotificationCount.value > 0 ? mdiBellRing : mdiBellSleep))

async function getNotifications(): Promise<void> {
  try {
    const response = await api.notification.getNotifications(onlyNotRead.value, 0, itemsPerLoad.value * loadCount.value)

    notReadNotificationCount.value = response.not_read_count
    notifications.value = response.notifications
  } catch {
    //
  }
}

async function loadNotifications({ done }: { done: Function }): Promise<void> {
  try {
    if (loadCount.value === 1) loadCount.value += 1

    const response = await api.notification.getNotifications(onlyNotRead.value, 0, itemsPerLoad.value * loadCount.value)

    notReadNotificationCount.value = response.not_read_count

    if (response.notifications.length === notifications.value.length) {
      done('empty')
      return
    }

    notifications.value = response.notifications

    done('ok')
    loadCount.value += 1
  } catch {
    //
  }
}

async function readAll(): Promise<void> {
  try {
    await api.notification.read('all')
    getNotifications()
  } catch {
    //
  }
}

async function read(notification: INotification): Promise<void> {
  try {
    await api.notification.read(notification.id)
    getNotifications()
  } catch {
    //
  }
}

async function unread(notification: INotification): Promise<void> {
  try {
    await api.notification.unread(notification.id)
    getNotifications()
  } catch {
    //
  }
}

watch(onlyNotRead, () => {
  loadCount.value = 1
  getNotifications()
})
</script>

<style lang="scss" scoped>
.v-switch {
  height: 40px;
}
</style>
