<template>
  <v-dialog v-if="opened" v-model="opened" persistent max-width="400px">
    <v-card class="pa-6">
      <v-card-text class="text-center">
        <v-icon class="fs-xxl mb-4" :class="icon.color" :icon="icon.name" />
        <div class="fs-ml fw-bold">{{ title }}</div>
        <div>{{ description }}</div>
      </v-card-text>

      <v-card-actions>
        <v-btn block @click="close">{{ t('ajax.okText') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useErrorDialogStore } from '@/stores/error-dialog'
import { mdiAlert, mdiCloseCircle } from '@mdi/js'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const errorDialog = useErrorDialogStore()

const opened = computed({
  get() {
    return errorDialog.opened
  },
  set() {
    close()
  },
})

const title = computed(() => errorDialog.title || t('ajax.unknown_error'))

const description = computed(() => errorDialog.description || t('ajax.unknown_error_description'))

const type = computed(() => errorDialog.type || 'error')

const icon = computed(() => {
  if (type.value === 'warning') {
    return {
      name: mdiAlert,
      color: 'text-warning',
    }
  }
  return {
    name: mdiCloseCircle,
    color: 'text-error',
  }
})

function close(): void {
  errorDialog.close()
}
</script>
