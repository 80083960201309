import type { IHistory, IParticipantStatistics } from '@/interfaces/history'
import type {
  IActiveStudies,
  IAttachment,
  IFilterParticipantRecruitment,
  IForm,
  IParamsFilterParticipant,
  IParticipantPaginated,
  IParticipantRecruitment,
  IStudy,
} from '@/interfaces/recruitment'
import { get, post, postFormData } from './ajaxutils'

export default {
  getParticipantsRecruitment(filters: IFilterParticipantRecruitment): Promise<Array<IParticipantRecruitment>> {
    return get('/api/recruitment/participants', { filters: JSON.stringify(filters) })
  },
  getHistory(participant_recruitment_id: number): Promise<Array<IHistory>> {
    return get('/api/recruitment/participant/history', { participant_recruitment_id })
  },
  getParcipantStatistics(participant_recruitment_id: number | null): Promise<IParticipantStatistics> {
    return get('/api/recruitment/participant/statistics', { participant_recruitment_id })
  },
  assignParticipantResponsible(
    participant_recruitment_id: number | null,
    responsible_id: number | null
  ): Promise<void> {
    return post('/api/recruitment/participant/responsible/add', { participant_recruitment_id, responsible_id })
  },
  unassignParticipantResponsible(participant_recruitment_id: number | null): Promise<void> {
    return post('/api/recruitment/participant/responsible/delete', { participant_recruitment_id })
  },
  updateParticipantsList(recruitment_id: number | null, filter_code: string): Promise<void> {
    return post('/api/recruitment/participants/update', { recruitment_id, filter_code })
  },
  getActiveStudies(participant_recruitment_id: number | null): Promise<Array<IActiveStudies>> {
    return get('/api/recruitment/participant/active_studies', { participant_recruitment_id })
  },
  getActiveStudiesList(participant_recruitment_id_list: string): Promise<{ [id: string]: Array<IActiveStudies> }> {
    return get('/api/recruitment/participant/active_studies_list', { participant_recruitment_id_list })
  },
  addParticipantObservation(
    participant_recruitment_id: number | null,
    schedule_id: number | null,
    observation: string
  ): Promise<void> {
    return post('/api/recruitment/participant/observation/add', {
      participant_recruitment_id,
      schedule_id,
      observation,
    })
  },
  noProfile(participant_recruitment_id: number | null, reason: string): Promise<void> {
    return post('/api/recruitment/participant/no_profile', { participant_recruitment_id, reason })
  },
  noAvailability(participant_recruitment_id: number | null, reason: string): Promise<void> {
    return post('/api/recruitment/participant/no_availability', { participant_recruitment_id, reason })
  },
  noInterest(participant_recruitment_id: number | null, reason: string): Promise<void> {
    return post('/api/recruitment/participant/no_interest', { participant_recruitment_id, reason })
  },
  getParticipants(params: IParamsFilterParticipant): Promise<IParticipantPaginated> {
    return get('/api/recruitment/participants/all', { params: JSON.stringify(params) })
  },

  //external
  getStudies(user_id: number | null): Promise<Array<IStudy>> {
    return get('/api/participant/studies', { user_id })
  },
  acceptTerms(recruitment_id: number | null, participant_id: number | null): Promise<Array<IAttachment>> {
    return post('/api/participant/study/terms/acceptance', { recruitment_id, participant_id })
  },
  saveForm(schedule_id: number | null, form: IForm): Promise<void> {
    return post('/api/participant/schedule/attachment/delete', { schedule_id, ...form })
  },
  addAttachment(schedule_id: number | null, file: File | null): Promise<Array<IAttachment>> {
    return postFormData('/api/participant/schedule/attachment/save', {
      schedule_id,
      file,
    })
  },
  removeAttachment(attachment_id: number | null): Promise<Array<IAttachment>> {
    return post('/api/participant/schedule/attachment/delete', { attachment_id })
  },
}
