import { useAuthStore } from '@/stores/auth'
import type { Component } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: (): Component => {
        const auth = useAuthStore()
        if (auth.isParticipant) return import('@/views/home/ParticipantHome.vue')
        return import('@/views/home/Home.vue')
      },
      meta: { requiresAuth: true },
    },
    {
      path: '/proposals',
      name: 'proposals',
      component: (): Component => import('@/views/proposal/Proposals.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (!auth.isSales && !auth.isSalesReadOnly && !auth.isTechnicalArea && !auth.isFinance)
          next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/entry-document',
      name: 'entryDocuments',
      component: (): Component => import('@/views/entry-document/EntryDocuments.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (!auth.isSales && !auth.isTechnicalArea) next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/study-plans',
      name: 'studyPlans',
      component: (): Component => import('@/views/study-plan/StudyPlans.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (!auth.isSales && !auth.isTechnicalArea) next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/recruitments',
      name: 'recruitments',
      component: (): Component => import('@/views/recruitment/Recruitments.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (
          !auth.isTaRecruitmentManager &&
          !auth.isTaRecruitmentCoordinator &&
          !auth.isRecruitmentManager &&
          !auth.isRecruitmentCoordinator
        )
          next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/groups',
      name: 'groups',
      component: (): Component => import('@/views/recruitment/Groups.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (
          !auth.isTaRecruitmentManager &&
          !auth.isTaRecruitmentCoordinator &&
          !auth.isRecruitmentManager &&
          !auth.isRecruitmentCoordinator &&
          !auth.isRecruitmentOperator &&
          !auth.isFinance
        )
          next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/attendance',
      name: 'attendance',
      component: (): Component => import('@/views/recruitment/Attendance.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (
          !auth.isTaRecruitmentManager &&
          !auth.isTaRecruitmentCoordinator &&
          !auth.isRecruitmentManager &&
          !auth.isRecruitmentCoordinator &&
          !auth.isRecruitmentOperator &&
          !auth.isRecepcionist &&
          !auth.isFinance
        )
          next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/payments',
      name: 'payments',
      component: (): Component => import('@/views/finance/Payments.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (
          !auth.isTaRecruitmentManager &&
          !auth.isTaRecruitmentCoordinator &&
          !auth.isRecruitmentManager &&
          !auth.isRecruitmentCoordinator &&
          !auth.isRecruitmentOperator &&
          !auth.isRecepcionist &&
          !auth.isFinance
        )
          next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/participants',
      name: 'participants',
      component: (): Component => import('@/views/participant/Participants.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (!auth.isRecruitmentManager && !auth.isRecruitmentCoordinator && !auth.isRecruitmentOperator)
          next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/thc',
      name: 'thc',
      component: (): Component => import('@/views/thc/Thc.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (!auth.isSampleRoom && !auth.isTechnicalArea) next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/products',
      name: 'products',
      component: (): Component => import('@/views/product/Products.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (!auth.isSales && !auth.isTechnicalAreaManager) next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/customers',
      name: 'customers',
      component: (): Component => import('@/views/customer/Customers.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (!auth.isSales && !auth.isSalesReadOnly && !auth.isFinance && !auth.isTechnicalAreaManager)
          next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/users',
      name: 'users',
      component: (): Component => import('@/views/auth/Users.vue'),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next): void => {
        const auth = useAuthStore()
        if (!auth.isStaff) next({ name: 'accessDenied' })
        else next()
      },
    },
    {
      path: '/login/:step?',
      name: 'login',
      component: (): Component => import('@/views/auth/Login.vue'),
      meta: { hideToolbar: true },
    },
    {
      path: '/register',
      name: 'register',
      component: (): Component => import('@/views/auth/Register.vue'),
      meta: { hideToolbar: true },
    },
    {
      path: '/expired-link',
      name: 'expiredLink',
      component: (): Component => import('@/views/auth/ExpiredLink.vue'),
      meta: { hideToolbar: true, errorPage: true },
    },
    {
      path: '/access-denied',
      name: 'accessDenied',
      component: (): Component => import('@/views/auth/AccessDenied.vue'),
      meta: { errorPage: true },
    },
  ],
})

router.beforeEach(async (to, from, next): Promise<void> => {
  const auth = useAuthStore()
  if (auth.currentUser === null) await auth.whoami()

  if (to.meta.errorPage) next()
  else if (!to.matched.some(record => record.meta.requiresAuth) && auth.loggedIn) next({ name: 'home' })
  else if (!to.matched.some(record => record.meta.requiresAuth)) next()
  else if (!auth.loggedIn) next({ name: 'login' })
  else next()
})

export default router
